@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  font-family: 'Noto Sans TC', 'PingFang TC', 'Microsoft JhengHei', 'Arial',
    'sans-serif';
  @apply text-base;
  @apply text-grey-1;
}

a {
  color: inherit;
  text-decoration: none;
  font-size: inherit;
  font-weight: inherit;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

*:focus {
  outline: none;
}

li {
  list-style: none;
}

.Toastify__progress-bar-theme--light {
  @apply bg-pink !important;
}

.scrollbar-disabled {
  /* disable scrollbar */
  -ms-overflow-style: none;
  scrollbar-width: none;
}

/* disable scrollbar indicator */
.scrollbar-disabled::-webkit-scrollbar {
  display: none;
}
